import React from "react"
import { graphql, Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

import { Layout } from "../components/layout"

const Feature = ({ image, alt, title, videomp4, videowebm }) => {
  if (videomp4 || videowebm) {
    return (
      <video autoPlay={true} loop={true} muted={true} playsInline={true}>
        {videowebm && <source src={videowebm} type="video/webm" />}
        {videomp4 && <source src={videomp4} type="video/mp4" />}
      </video>
    )
  }

  return (
    <GatsbyImage
      style={{
        width: "220px",
        maxWidth: "100%",
        margin: "0 auto",
      }}
      image={image.childImageSharp.gatsbyImageData}
      layout="constrained"
      loading="eager"
      alt={alt}
      title={title}
    />
  )
}

const HomePageTemplate = ({ data, location }) => {
  const featuredWork = data.allMarkdownRemark.edges.map(({ node }) => node)

  return (
    <Layout location={location} title="Home" className="page-home">
      <ul className="projects">
        {featuredWork.map(({ frontmatter, fields, id }) => (
          <li key={id}>
            <Link className="project" to={"/" + fields.slug}>
              <Feature {...frontmatter.featureimage} />
              <div className="content">
                <h2>{frontmatter.title}</h2>
                <p>{frontmatter.description}</p>
              </div>
            </Link>
          </li>
        ))}
      </ul>
    </Layout>
  )
}

export default HomePageTemplate

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(frontmatter: { templateKey: { eq: "home-page" } }) {
      id
      html
      frontmatter {
        title
      }
    }
    allMarkdownRemark(
      filter: { fields: { sourceInstanceName: { eq: "work" } } }
      sort: { order: ASC, fields: [frontmatter___rank] }
    ) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            featureimage {
              image {
                childImageSharp {
                  gatsbyImageData(
                    layout: CONSTRAINED
                    width: 220
                    height: 320
                    quality: 100
                    placeholder: TRACED_SVG
                  )
                }
              }
              videowebm
              videomp4
              alt
              title
            }
            title
            description
          }
        }
      }
    }
  }
`
